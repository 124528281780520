<template>
    <div>
        <b-row v-if="infromationChannel">
            <information-channel :infromation-channel="infromationChannel" />
            <score-master-tube />
            <channel-status />
            <section-charts />
            <upload-video />
            <channel-name :infromation-channel="infromationChannel" />
            <channel-profile :infromation-channel="infromationChannel" />
            <channel-art :infromation-channel="infromationChannel" />
            <channel-keywords :infromation-channel="infromationChannel" />
            <channel-description :infromation-channel="infromationChannel" />
            <channel-trailer :infromation-channel="infromationChannel" />
            <search-result :infromation-channel="infromationChannel" />
            <!-- <channel-links :infromation-channel="infromationChannel" /> -->
            <!-- <channel-email :infromation-channel="infromationChannel" /> -->
            <channel-watermark :infromation-channel="infromationChannel" />
            <channel-section />
            <channel-playlists />
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import chartjsData from "./components/chartjsData";
import informationChannel from "./components/information-channel.vue";
import scoreMasterTube from "./components/score-master-tube.vue";
import channelStatus from "./components/channel-status.vue";
import uploadVideo from "./components/uploadVideo.vue";
import channelName from "./components/channel-name.vue";
import channelProfile from "./components/channel-profile.vue";
import channelArt from "./components/channel-art.vue";
import channelKeywords from "./components/channel-keywords.vue";
import channelDescription from "./components/channel-description.vue";
import channelTrailer from "./components/channel-trailer.vue";
import searchResult from "./components/search-result.vue";
import sectionCharts from "./components/section-charts.vue";
import channelLinks from "./components/channel-links.vue";
import ChannelEmail from "./components/channel-email.vue";
import ChannelWatermark from "./components/channel-watermark.vue";
import ChannelSection from "./components/channel-sections.vue";
import ChannelPlaylists from "./components/channel-playlists.vue";
import ChannelTabs from "./components/channel-tabs.vue"

export default {
    components: {
        VueApexCharts,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BForm,
        BButton,
        BCard,
        BAlert,
        vSelect,
        BFormDatepicker,
        BInputGroup,
        BInputGroupAppend,
        BCardText,
        BAvatar,
        BTable,
        BImg,
        BBadge,
        BLink,
        BTooltip,
        BMedia,
        BMediaAside,
        BMediaBody,
        BCardHeader,
        BModal,
        BCardBody,
        BOverlay,
        BCardTitle,
        BPaginationNav,
        BCardSubTitle,
        informationChannel,
        scoreMasterTube,
        channelStatus,
        uploadVideo,
        channelName,
        channelProfile,
        channelArt,
        channelKeywords,
        channelDescription,
        channelTrailer,
        searchResult,
        sectionCharts,
        channelLinks,
        ChannelEmail,
        ChannelWatermark,
        ChannelSection,
        ChannelPlaylists,
        ChannelTabs,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            infromationChannel: null,
        }
    },
    created() {
        this.getChannelInformation();
    },
    methods: {
        getChannelInformation() {
            this.$http.get(`/api/tools/assistant/channel-information?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`)
                .then((response) => {
                    this.infromationChannel = response.data;
                    let tags = this.infromationChannel.keywords.split('" "')
                    let items = []
                    tags.map(item => {
                        items.push(item.replace('"', ""))
                    })
                    this.infromationChannel.keywords = tags;
                });
        },
    },
}
</script>

<style>
.collpase-item {
    border-radius: 0.428rem !important;
}

.collpase-item.text-success {
    border-color: var(--success) !important;
}

.collpase-item.text-danger {
    border-color: var(--danger) !important;
}

.collpase-item.text-success :after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.collpase-item.text-danger :after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.collpase-item hr {
    margin: 0 !important;
    margin-bottom: 1rem !important;
}

.text-color {
    color: #b4b7bd !important;
    margin-bottom: 0 !important;
}

.collpase-item ul {
    list-style: none !important;
    padding-inline-start: 0 !important;
    margin-block-end: 0 !important;
}

.collpase-item ul li {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    color: var(--success);
}

.collpase-item ul li h4 {
    margin-bottom: 0 !important;
    color: var(--success) !important;
    font-size: 18px !important;
    font-weight: bold !important;
}

.collpase-item ul p {
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
}

.collpase-item ul p:last-child {
    margin-bottom: 0 !important;
}

.collpase-item video {
    border-radius: 0.428rem !important;
    border: 1px solid #3b4253 !important;
    display: flex !important;
}

.special-form {
    padding: 0 !important;
    border: none !important;
}

.special-form .badge {
    margin-bottom: 0.4rem !important;
    margin-top: 0.4rem !important;
}
.special-radius {
    border-radius: 0.428rem !important;
}
.demo-inline-spacing {
    display: flex !important;
    align-items: center !important;
    gap: 20px !important;
    justify-content: center !important;
    text-align: center;
}
.analytics-vue .card {
    height: calc(100% - 2rem);
}

</style>